import User1 from './user1.jpeg';
import User2 from './user2.jpeg';

const hostjaneSliderData = {
	title: 'Own your project',
	subtitle:
		"HostJane's boards, lists, and cards make it easy for visual learners to control progress.",
	slideMainTitle: 'Build website',
	membersAvatars: [User1, User2],
	cards: [
		{
			cards: [{ addList: true }],
			bottomLabel: 'Email notifications',
		},
		{
			cards: [
				{
					title: 'To Do',
					addList: false,
					addCard: true,
					avatars: [User1, User2],
				},
				{ title: 'Doing', addCard: true, addList: false },
				{ title: 'Done', addCard: true, addList: false },
			],
			bottomLabel: 'Saved for every order',
		},
		{
			cards: [
				{
					title: 'To Do',
					addCard: true,
					listItems: [
						{ desc: 'Footer' },
						{ desc: 'WooCommerce shop' },
					],
				},
				{
					title: 'Doing',
					addCard: true,
					listItems: [
						{ desc: 'Slider' },
						{ desc: 'Speed up homepage' },
						{ desc: 'Install CDN' },
						{ desc: 'Google Adsense' },
					],
				},
				{
					title: 'Done',
					addCard: true,
					listItems: [{ desc: 'About page' }, { desc: 'Header' }],
				},
			],
			bottomLabel: 'Track + restore changes',
		},
		{
			cards: [
				{ title: 'To Do', addList: false },
				{ title: 'Doing', addCard: true },
			],
			bottomLabel: 'Share files + checklists',
			showModal: true,
			modalData: {
				title: 'Speed up homepage',
				description: 'I want my website to load in 1 second.',
				checklist: [
					'Install WP Rocket',
					'Move to better host',
					'Enable server cache',
				],
				membersAvatars: [User2, User1],
				dueDate: 'Jan 1',
			},
		},
		{
			bottomLabel: 'Keep on the same page',
			cards: [
				{
					title: 'To Do',
					addCard: true,
					listItems: [
						{ desc: 'Footer' },
						{ desc: 'WooCommerce shop' },
					],
				},
				{
					title: 'Doing',
					addCard: true,
					listItems: [
						{ desc: 'Slider' },
						{ desc: 'Speed up homepage', inMotion: true },
						{ desc: 'Install CDN' },
						{ desc: 'Google Adsense' },
					],
				},
				{
					title: 'Done',
					addCard: true,
					listItems: [{ desc: 'About page' }, { desc: 'Header' }],
				},
			],
		},
	],
};

export default hostjaneSliderData;
