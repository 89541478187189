import React from 'react';

export const title = 'Application Hosting for Amazon EC2';

export const explore = {
	url: 'https://cloud.hostjane.com/cloud/', // give 'null' value to URL to remove button
	label: 'Try Cloud Hosting',
	internal: false,
};

export function Description() {
	return <>Expertly configured virtual machines include free server set up and ongoing management.</>;
}
