import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useScrollPosition from '@react-hook/window-scroll';
import scrollToElement from 'scroll-to-element';
import * as Scroll from 'react-scroll';
import {
	Link,
	Element,
	Events,
	animateScroll as scroll,
	scrollSpy,
	scroller,
} from 'react-scroll';
import { useScrollData } from 'scroll-data-hook';
import useWindowSize from '../../utils/useWindowSize';

const ScrollIndicatorWrapper = styled.div`
	&.hostjane-main__scrollIndicator {
		.hostjane-main__scrollIndicator__indicator-circles__section-scroll {
			&.active {
				.hostjane-main__scrollIndicator__animated-scroll {
					display: none;
				}
			}
		}
		.hostjane-main__scrollIndicator__animated-scroll {
			left: auto;
			right: 10px;
			bottom: 50px;
			transform: none;
			position: fixed;
			width: 50px;
			z-index: 10000;
			@media only screen and (max-width: 992px) {
				position: fixed;
				width: 50px;
				left: 50%;
				bottom: 100px;
				transform: translateX(-50%);
			}
			.hostjane-main__scrollIndicator__animated-scroll__arrows {
				position: absolute;
				left: 50%;
				height: 20px;
				display: block;
				transform: translateX(-50%);
				${props =>
					!props.displayScrollIndicator &&
					`
					display: none;
				`}

				${props =>
					props.reachedBottom &&
					`
					display: none;
				`}
				&:before {
					content: '';
					opacity: 0;
					width: 12px;
					height: 12px;
					border-right: 3px solid #666;
					border-bottom: 3px solid #666;
					transform: rotate(45deg);
					animation: si-arrow-drop 1s infinite;
					top: -50%;
					animation-delay: 0.15s;
					display: block;
					position: absolute;
				}
				@keyframes si-arrow-drop {
					0% {
						opacity: 1;
					}
					to {
						opacity: 0;
					}
				}
				&:after {
					top: 50%;
					animation-delay: 0.45s;
					display: block;
					position: absolute;
					content: '';
					opacity: 0;
					width: 12px;
					height: 12px;
					border-right: 3px solid #666;
					border-bottom: 3px solid #666;
					transform: rotate(45deg);
					animation: si-arrow-drop 1s infinite;
				}
				div {
					animation-delay: 0.3s;
					opacity: 0;
					width: 12px;
					height: 12px;
					border-right: 3px solid #666;
					border-bottom: 3px solid #666;
					transform: rotate(45deg);
					animation: si-arrow-drop 1s infinite;
				}
			}
		}
		.hostjane-main__scrollIndicator__indicator-circles {
			position: fixed;
			display: block;
			z-index: 6002;
			opacity: 0;
			top: 85%;
			right: 30px;
			transform: translateY(-50%);
			padding: 0;
			padding-left: 10px;
			transition: top 0.8s, opacity 0.8s;
			${props =>
				props.scrollY > 0 &&
				`
			top: 50%;
			opacity: 1;
			`}
			@media screen and (max-width: 992px) {
				display: none;
			}
			.hostjane-main__scrollIndicator__indicator-circles__section-menu {
				color: #005b86;
				position: relative;
				display: block;
				width: 10px;
				height: 10px;
				background-color: #aeaeaf;
				border-radius: 50%;
				margin-bottom: -10px;
				outline: none;
				transition: margin-bottom 0.8s;
				text-decoration: none;
				${props =>
					props.scrollY > 0 &&
					`
					margin-bottom: 10px;
				`}
				&.active {
					background-color: #3c3f45;
				}
				&:hover {
					div {
						opacity: 1;
						visibility: visible;
					}
					span {
						visibility: visible;
					}
				}
				span {
					visibility: hidden;
					position: absolute;
					opacity: 0;
					right: 0;
					top: -8px;
					height: 25px;
					padding: 0 15px;
					border-radius: 10px;
					text-align: center;
					line-height: 25px;
					overflow: hidden;
					white-space: nowrap;
					text-decoration: none;
					font-size: 13px;
					color: #fff;
					// background-color: #3c3f45;
					user-select: none;
					transition: opacity 0.2s;
					cursor: pointer;
				}
				div {
					visibility: hidden;
					position: absolute;
					opacity: 0;
					right: 15px;
					top: -8px;
					height: 25px;
					padding: 0 15px;
					border-radius: 10px;
					text-align: center;
					line-height: 25px;
					overflow: hidden;
					white-space: nowrap;
					text-decoration: none;
					font-size: 13px;
					color: #fff;
					background-color: #3c3f45;
					user-select: none;
					transition: opacity 0.2s;
					cursor: pointer;
				}
			}
		}
	}
`;

const ScrollIndicator = ({ sectionIds }) => {
	const windowWidth = useWindowSize();
	const sectionNames = Object.keys(sectionIds);
	const eachSectionId = Object.values(sectionIds);
	const scrollY = useScrollPosition(60 /* frames per second */);
	const [displayScrollIndicator, setDisplayScrollIndicator] = useState(true);
	const [reachedBottom, setReachedBottom] = useState(false);
	const {
		scrolling,
		time,
		speed,
		direction,
		position,
		relativeDistance,
		totalDistance,
	} = useScrollData({
		onScrollStart: () => {
			setDisplayScrollIndicator(false);
		},
		onScrollEnd: () => {
			setTimeout(() => {
				setDisplayScrollIndicator(true);
			}, 2000);
		},
	});

	useEffect(() => {
		if (windowWidth[0] > 576) {
			if (scrollY === 0) {
				setTimeout(() => {
					setDisplayScrollIndicator(true);
				}, 2000);
			} else {
				setDisplayScrollIndicator(false);
			}
		}

		const footerHeight = document
			.getElementById('footer-main')
			.getBoundingClientRect().height;
		const scrollBottom =
			document.body.scrollHeight - window.innerHeight - scrollY;
		if (footerHeight > scrollBottom) {
			setReachedBottom(true);
		} else {
			setReachedBottom(false);
		}
	}, [scrollY, windowWidth]);

	return (
		<ScrollIndicatorWrapper
			scrollY={scrollY}
			className="hostjane-main__scrollIndicator"
			displayScrollIndicator={displayScrollIndicator}
			reachedBottom={reachedBottom}
		>
			<Link
				activeClass="active"
				to="footer-main"
				spy
				smooth
				offset={-200}
				duration={500}
				style={{ pointerEvents: 'none' }}
				className="hostjane-main__scrollIndicator__indicator-circles__section-scroll"
			>
				<div className="hostjane-main__scrollIndicator__animated-scroll">
					<div className="hostjane-main__scrollIndicator__animated-scroll__arrows">
						<div />
					</div>
				</div>
			</Link>
			<div className="hostjane-main__scrollIndicator__indicator-circles">
				{eachSectionId.map((value, key) => {
					return (
						<Link
							activeClass="active"
							to={value}
							spy
							smooth
							offset={-200}
							duration={500}
							// onSetActive={this.handleSetActive}
							href="#_"
							onClick={e => {
								e.preventDefault();
							}}
							className="hostjane-main__scrollIndicator__indicator-circles__section-menu"
						>
							<div
								onClick={() => {
									scrollToElement(`#${value}`, {
										offset: 0,
										duration: 1500,
									});
								}}
							>
								{sectionNames[key]}
							</div>
							<span />
						</Link>
					);
				})}
			</div>
		</ScrollIndicatorWrapper>
	);
};

export default ScrollIndicator;
