import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { ReactComponent as GoogleBtn } from '../../../svgs/icons/google.svg';
import { ReactComponent as AppleBtn } from '../../../svgs/icons/apple_icon.svg';
import { ReactComponent as TelegramBtn } from '../../../svgs/icons/telegram-app.svg';

const HostjaneSellersWrapper = styled.section`
	&.hostjane-main-hostjanesellers {
		background-color: rgb(240, 242, 245);
		background-image: linear-gradient(
			315deg,
			rgb(255, 255, 255) 0%,
			rgb(240, 242, 245) 74%
		);
		color: #000000;
		.hostjane-main-hostjanesellers__title {
			text-align: center;
			> div {
				display: block;
				margin-left: auto;
				margin-right: auto;
				h1 {
					font-size: 60px;
					line-height: 70px;
					font-weight: 800;
					letter-spacing: 0.5px;
					padding: 30px 12px 12px;
					margin-bottom: 0;
					margin-top: 0;
					span {
						position: relative;
						&:after {
							content: '';
							position: absolute;
							display: block;
							width: 100%;
							height: 0.3em;
							background-image: url("data:image/svg+xml,%3Csvg width='236' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.8 8.3l16.8-.6L35 7.1l15-.7c2.2-.1 4.5-.3 6.8-.3l10-.2h5.5l10.3.2 15.4.6L129.5 8l15.5.8 15.4.7c9.7.4 19.4.7 29 1.3l8 .5 8.9.7 7.7.7 3.2.3 3.7.5 5.2.6 6.6 1.2.6.1a2.3 2.3 0 002.3-1.6c.2-.6.1-1.3-.2-1.9-.2-.4-.8-1-1.3-1.1l-4.2-.8-4.2-.7A403.3 403.3 0 00212 7.7a2.4 2.4 0 001-2.4c-.2-.6-.6-1.2-1-1.5l-1.1-.5a33 33 0 00-2.6-.4l-2-.3-3.8-.2L195 2l-15.5-.5-15.4-.3L156 1h-24.4L115.8.8C106.1.7 96.2.6 86.4.7H71.8c-5.2 0-10.4 0-15.6.2L45.9 1l-5.9.1-9.4.4-6.2.3c-7.4.5-14.7 1-22 1.7-.5.1-.8.3-1.1.6-.5.4-.8 1-.8 1.6 0 1.3 1 2.5 2.3 2.5' fill='%231dbf73' fill-rule='evenodd'/%3E%3C/svg%3E");
							background-size: 100% 100%;
							background-repeat: no-repeat;
							background-position: bottom;
							left: 0;
							bottom: -0.1em;
							z-index: 1;
						}
					}
					@media screen and (max-width: 576px) {
						font-size: 40px;
						font-weight: 800;
						padding-bottom: 6px;
						margin-bottom: 0px;
						line-height: 48px;
						padding-top: 24px;
					}
				}
			}
		}
		.hostjane-main-hostjanesellers__section {
			max-width: 1400px;
			margin: 0 auto;
			padding: 0px 36px;
			padding-bottom: 30px;
			text-align: center;
			@media screen and (max-width: 576px) {
				padding: 0px;
				padding-bottom: 18px;
			}

			.login-buttons {
				display: grid;
				grid-template-columns: auto auto auto;
				justify-content: center;
				margin: 20px 0px 40px;
				padding: 0px;
				grid-gap: 20px;
				align-items: center;
				justify-content: center;
				a {
					/* max-width: 270px; */
					width: 100%;
					align-items: center;
					justify-content: center;
					background-color: rgb(255, 255, 255);
					border-radius: 32px;
					box-shadow: rgb(11 43 158 / 15%) 0px 6px 20px -6px;
					color: rgb(0 0 0);
					display: flex;
					font-weight: 600;
					margin-right: 24px;
					padding: 12px 24px 12px 16px;
					transition: all 0.2s ease 0s;
					text-decoration: none;
					&:hover {
						background-color: #e41937;
						color: #ffffff;
						transition: all 0.2s ease 0s;
					}

					div {
						display: grid;
						grid-template-columns: 40px auto;
						align-items: center;
						span {
							text-align: initial;
						}
					}
					svg {
						max-width: 24px;
						max-height: 24px;
						/* margin-right: 16px; */
					}

					&:nth-of-type(3) {
						align-items: center;
						background-color: rgb(255, 255, 255);
						border-radius: 32px;
						box-shadow: rgb(8 27 75 / 35%) 0px 6px 20px -6px;
						color: rgb(0 0 0);
						padding: 12px 24px 12px 16px;
						transition: all 0.2s ease 0s;
						&:hover {
							background-color: #007bfd;
							color: #ffffff;
							transition: all 0.2s ease 0s;
						}

						svg {
							max-width: 24px;
							max-height: 24px;
						}
					}
				}

				@media screen and (max-width: 992px) {
					display: grid;
					grid-template-columns: 1fr;
					width: 280px;
					margin-left: auto;
					margin-right: auto;
					a {
						align-items: center;
						justify-content: center;
						div {
							max-width: 280px;
							width: 100%;
						}
					}
				}
				@media screen and (max-width: 576px) {
					width: 280px;
					margin-left: auto;
					margin-right: auto;
				}
			}

			h2 {
				font-size: 40px;
				line-height: 50px;
				font-weight: 400;
				font-style: italic;
				padding-bottom: 12px;
				margin-top: 5px;
				color: #000000;
				@media screen and (max-width: 576px) {
					font-size: 30px;
					line-height: 38px;
					font-weight: 400;
					font-style: italic;
					margin-top: 0;
					padding-left: 20px;
					padding-right: 20px;
				}
			}
			.hostjane-main-hostjanesellers__section__shopping-items {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				@media screen and (min-width: 1024px) {
					flex-wrap: nowrap;
				}
				@media screen and (max-width: 768px) {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr;
				}
				.hostjane-main-hostjanesellers__section__shopping-items__item {
					padding: 9px;
					padding-left: 18px;
					padding-right: 18px;
					text-align: center;
					@media screen and (max-width: 768px) {
						padding-left: 0;
						padding-right: 0;
						padding: 6px;
					}
					a {
						font-weight: bold;
						font-size: 18px;
						text-decoration: none;
						display: inline-block;
						color: inherit;
						transition: opacity 200ms ease-out;
						line-height: 18px;
						@media screen and (max-width: 576px) {
							font-size: 15px;
						}
						img {
							display: block;
							border-radius: 50%;
							overflow: hidden;
							max-width: 160px;
							margin-bottom: 12px;
							@media screen and (min-width: 1024px) {
								max-width: 100%;
							}
							@media screen and (max-width: 768px) {
								max-width: 100%;
							}
						}

						svg {
							width: 12px;
							height: 24px;
							transition: all 100ms ease-out;
							transform: translate(2px, 8px);
						}
						&:hover {
							svg {
								transform: translate(5px, 8px);
							}
						}
						@media screen and (max-width: 576px) {
							svg {
								display: none;
							}
						}
					}
				}
			}
		}
	}
`;

const HostjaneSellers = ({
	id,
	shoppingItems,
	title,
	subtitle,
	rightArrow,
	titleColor,
	authButtons,
}) => {
	return (
		<Element name={id}>
			<HostjaneSellersWrapper
				titleColor={titleColor}
				className="hostjane-main-hostjanesellers"
			>
				<div className="hostjane-main-hostjanesellers__title">
					<div>
						<h1 dangerouslySetInnerHTML={{ __html: title }} />
					</div>
				</div>
				<div className="hostjane-main-hostjanesellers__section">
					<h2>{subtitle}</h2>
					<div className="login-buttons">
						{authButtons.map(({ text, link, icon }, key) => (
							<a href={link} key={title}>
								<div>
									{icon}
									<span>{text}</span>
								</div>
							</a>
						))}
					</div>
					<div className="hostjane-main-hostjanesellers__section__shopping-items">
						{shoppingItems.map(
							({ images, img: image, title, link }, key) => {
								const randomIndex = Math.floor(
									Math.random() * 10
								);
								const img = images?.[randomIndex]
									? images?.[randomIndex]
									: image;

								console.log('number of images', randomIndex);
								return (
									<div
										key={`${key + title}`}
										className="hostjane-main-hostjanesellers__section__shopping-items__item"
									>
										<a href={link}>
											<img src={img} alt="" />
											{title} {rightArrow}
										</a>
									</div>
								);
							}
						)}
					</div>
				</div>
			</HostjaneSellersWrapper>
		</Element>
	);
};

export default HostjaneSellers;
