const homeCampaignsData = [
	{
		campaignThumbnail: require('./vps-hosting.png'),
		title: 'VPS Hosting',
		btnText: '$34.95/MO',
		btnLink: '/vps-hosting/#hostjane-buy-hosting',
	},
	{
		campaignThumbnail: require('./mobile-hosting.png'),
		title: 'Compare VPS',
		btnText: '24/7 Help',
		btnLink: '/vps-hosting/#compare-plans',
	},
];

export default homeCampaignsData;
