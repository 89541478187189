const sectionIds = {
	Solutions: 'explore',
	'How it works': 'how-it-works',
	Creatives: 'service-slider2',
	'Global Network': 'map',
	Developers: 'service-slider1',
	'Customer stories': 'testimonials',
	Innovation: 'features',
};

export default sectionIds;
