import React from 'react';

import { SafeGuardModal } from '../../modals/safeguard';
import { useModal } from '../../../src/utils/globalModal';

export const title = 'Skills to assist you';

export const explore = {
	url: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers', // give 'null' value to URL to remove button
	label: 'Find A Freelancer',
	internal: false,
};

export function Description() {
	const [open, openModal, closeModal] = useModal();

	return (
		<>
			Hire developers and programmers in our{' '}
			<button
				type="button"
				onClick={e => {
					e.preventDefault();
					openModal();
				}}
			>
				safe marketplace.
			</button>
			<SafeGuardModal open={open} closeModal={closeModal} />
		</>
	);
}
