import { ReactComponent as MoneyBackIcon } from './Money-back-guarantee.svg';
import { ReactComponent as NoHiddenFeesIcon } from './no-hidden-fees.svg';
import { ReactComponent as SupportIcon } from './24-7-support.svg';

export default {
	heading: 'Save time with AWS servers',
	items: [
		{
			title: 'Pick a Server',
			description:
				'Set up your AWS server and domain with free SSL.',
			icon: MoneyBackIcon,
			link: 'https://help.hostjane.com/cloud-hosting/',
			linkLabel: 'Learn more',
			internalLink: false,
		},
		{
			title: 'Server emails you',
			description:
				'Your Amazon server installs then sends you its IP and login details in 20 mins!',
			icon: NoHiddenFeesIcon,
			link: 'https://cloud.hostjane.com/wordpress/',
			linkLabel: 'Launch a WordPress server',
			internalLink: false,
		},
		{
			title: 'Point your domain',
			description:
				'Update the A Record to your hosting to see your site in any web browser.',
			icon: SupportIcon,
			link: 'https://help.hostjane.com/point-your-domain',
			linkLabel: 'How to point your domain',
			internalLink: false,
		},
	],
};
