import React from 'react';
import styled from 'styled-components';

const WhatIsHostjaneWrapper = styled.section`
	&.hostjane-main-whatsHostjane {
		position: relative;
		overflow: hidden;
		padding-top: 120px;
		&__background {
		}
		&__background-mask {
		}
		h2,
		h3,
		p,
		span,
		a {
			z-index: 1;
		}

		&:before {
			content: '';
			position: absolute;
			background-color: transparent;
			background-color: #f6f9fc;
			min-height: 610px;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: skewY(-6deg);
			transform-origin: 100% 0;
		}
		@media screen and (max-width: 1200px) {
			padding-top: 25px;
		}
		@media screen and (max-width: 992px) {
			padding-top: 0px;
		}
		.hostjane-main-whatsHostjane__body {
			max-width: 1550px;
			padding-left: 18px;
			padding-right: 18px;
			@media screen and (max-width: 576px) {
				padding-left: 0px;
				padding-right: 0px;
			}
			float: none;
			margin: 0 auto;
			.hostjane-main-whatsHostjane__body__column {
				width: 100%;
				padding-left: 18px;
				padding-right: 18px;
				@media screen and (max-width: 576px) {
					padding-left: 0px;
					padding-right: 0px;
				}
				.hostjane-main-whatsHostjane__body__column__container {
					padding: 36px;

					margin-top: 30px;
					overflow: hidden;
					min-height: 300px;
					margin: 0 auto;
					.hostjane-main-whatsHostjane__body__column__container__full-width {
						width: 100%;
						span {
							position: absolute;
							line-height: 0;
							z-index: 4;
							overflow: hidden;
							svg {
								width: 200px;
								height: 300px;
							}
							&.bg-1 {
								float: left;
								top: auto;
								bottom: -90px;
								left: 0;
							}
							&.bg-2 {
								float: right;
								top: -48px;
								right: 100px;
							}
							&.bg-3 {
								float: right;
								bottom: 0px;
								right: -100px;
							}
							&.bg-4 {
								float: left;
								bottom: 0;
								left: 0;
							}
						}
					}
					.hostjane-main-whatsHostjane__body__column__container__info {
						display: flex;
						flex-direction: column;
						max-width: 1402px;
						padding: 36px 18px;
						@media screen and (max-width: 576px) {
							padding-left: 0px;
							padding-right: 0px;
						}
						text-align: center;
						margin: 0 auto;

						h2 {
							z-index: 1;
							margin-bottom: 12px;
							line-height: 48px;
							font-size: 45px;
							font-weight: 800;
							color: black;
							@media screen and (max-width: 576px) {
								font-size: 32px;
							}
						}
						> a {
							color: #3c823e;
							text-decoration: underline;
							transition: opacity 200ms ease-out;
							position: relative;
							z-index: 1;
							background: transparent;
							text-align: center;
							font-size: 20px;
							&:hover {
								opacity: 0.5;
							}
						}
						.hostjane-main-whatsHostjane__body__column__container__info_boxes {
							padding-left: 18px;
							padding-right: 18px;
							@media screen and (max-width: 576px) {
								padding-left: 0px;
								padding-right: 0px;
							}
							padding-top: 36px;
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;
							@media screen and (max-width: 1200px) {
								flex-direction: column;
							}
							.hostjane-main-whatsHostjane__body__column__container__info__boxes__box {
								padding-left: 24px;
								padding-right: 24px;
								margin-bottom: 12px;
								width: 33.33333%;
								@media screen and (max-width: 1200px) {
									width: 100%;
									max-width: 400px;
								}
								h3 {
									z-index: 1;
									margin-bottom: 20px;
									line-height: 36px;
									font-size: 30px;
									font-weight: 800;
									letter-spacing: 0.5px;
									text-align: center;
									@media screen and (max-width: 576px) {
										font-size: 24px;
									}
								}
								p {
									z-index: 1;
									font-weight: 400;
									font-size: 20px;
									line-height: 32px;
									margin: 0;
									text-align: center;
								}
							}
						}
						.hostjane-main-whatsHostjane__body__column__container__info_bottom-info {
							padding-left: 18px;
							padding-right: 18px;
							position: relative;
							margin: 0;
							h4 {
								z-index: 1;
								margin-top: 24px;
								font-size: 20px;
								line-height: 28px;
								font-weight: 500;
							}
							a {
								z-index: 1;
								cursor: pointer;
								color: black;
								background: none;
								border: none;
								font: inherit;
								cursor: pointer;
								outline: none;
								text-decoration: none;
								border-radius: 24px;
								display: inline-block;
								font-size: 18px;
								font-weight: bold;
								line-height: 1.5;
								min-height: 48px;
								min-width: 48px;
								padding-top: 12px;
								padding-bottom: 12px;
								padding-left: 18px;
								padding-right: 18px;
								position: relative;
								text-align: center;
								vertical-align: middle;
								z-index: 10;
								&:before {
									content: '';
									background: inherit;
									border: inherit;
									border-radius: inherit;
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									z-index: -1;
									border-width: 2px;
									border-color: #222222;
									border-style: solid;
									transition: transform 200ms
											cubic-bezier(
												0.345,
												0.115,
												0.135,
												1.42
											),
										opacity 150ms ease-out;
									box-shadow: 0 4px 20px
										rgba(34, 34, 34, 0.15);
									opacity: 0;
									transform: rotate(0.0001deg);
								}
								&:after {
									background: inherit;
									border: inherit;
									border-radius: inherit;
									border-width: 2px;
									border-color: #222222;
									border-style: solid;
									transition: transform 200ms
											cubic-bezier(
												0.345,
												0.115,
												0.135,
												1.42
											),
										background 150ms ease-out,
										box-shadow 200ms ease-out;
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									opacity: 1;
								}
								&:hover {
									&:before {
										opacity: 1;
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

const MainWrapper = styled.main`
	position: relative;
	.top-left {
		overflow: hidden;
		position: absolute;
		left: 0;
		top: -72px;
		width: 100%;
		height: 72px;
		transform: skewY(-6deg) translateY(-0.35px);
		transform-origin: 100% 0;
		.hostjane-main-whatsHostjane__bg_strip_1 {
			position: relative;
			width: 160px;
			height: 36px;
			background-color: #80e9ff;
			transform: translateY(16px);
			left: calc(15% - 160px);

			@media screen and (max-width: 1400px) {
				width: 100px;
				left: 0;
			}
			&:before {
				position: absolute;
				content: '';
				left: 0;
				right: 0;
				bottom: 0;
				height: 16px;
				background-color: #0048e5;
			}
		}
		.hostjane-main-whatsHostjane__bg_strip_3 {
			z-index: -1;
			position: relative;
			height: 36px;
			width: calc(
				calc(calc(100vw - 15px) - 1080px) / 2 +
					calc(calc(1080px / 4) * 0.25)
			);
			@media screen and (max-width: 1400px) {
				width: 150px;
			}
			background-color: #7a73ff;
		}
	}
	.top-right {
		overflow: hidden;
		position: absolute;
		right: 0;
		top: -36px;
		transform: skewY(-6deg) translateY(-0.35px);
		transform-origin: 100% 0;
		.hostjane-main-whatsHostjane__bg_strip_2 {
			position: relative;
			width: 160px;
			height: 36px;
			background-color: #7a73ff;
			transform: translateY(-16px);
			left: calc(75% - 160px);

			@media screen and (max-width: 1400px) {
				width: 100px;
				left: calc(100% - 100px);
			}
			&:before {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				height: 16px;
				background-color: #0048e5;
			}
		}
		.hostjane-main-whatsHostjane__bg_strip_4 {
			position: relative;
			height: 36px;
			background-color: #80e9ff;
			width: calc(
				calc(calc(100vw - 15px) - 1080px) / 2 +
					calc(calc(1080px / 4) * 0.25)
			);
			left: calc(
				100% -
					calc(
						calc(calc(100vw - 15px) - 1080px) / 2 +
							calc(calc(1080px / 4) * 0.25)
					)
			);

			@media screen and (max-width: 1400px) {
				width: 150px;
				left: calc(100% - 150px);
			}
		}
	}

	.background-mask {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 0 16px;
		z-index: 0;
		.background-mask__grid {
			max-width: 1186px;
			height: 100%;
			margin: 0 auto;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			height: 100%;
			.background-mask__grid__item {
				background: linear-gradient(
					180deg,
					rgba(66, 71, 112, 0.09),
					rgba(66, 71, 112, 0.09) 50%,
					transparent 0,
					transparent
				);
				background-size: 1px 8px;
				width: 1px;
			}
		}
	}
`;

const WhatIsHostjane = ({
	title,
	subtitle,
	backgroundImages,
	bottomText,
	bottomBtnText,
	bottomBtnLink,
	cards,
	id,
}) => {
	return (
		<MainWrapper id={id} className="main">
			<WhatIsHostjaneWrapper className="hostjane-main-whatsHostjane">
				<div className="hostjane-main-whatsHostjane__background">
					<div className="hostjane-main-whatsHostjane__background-mask">
						<div className="hostjane-main-whatsHostjane__body">
							<div className="hostjane-main-whatsHostjane__body__column">
								<div className="hostjane-main-whatsHostjane__body__column__container">
									<div className="hostjane-main-whatsHostjane__body__column__container__info">
										<h2> {title}</h2>
										<a href="/about"> {subtitle} </a>
										<div className="hostjane-main-whatsHostjane__body__column__container__info_boxes">
											{cards.map(
												(
													{ title, description },
													key
												) => {
													return (
														<div
															key={`${
																title + key
															}`}
															className="hostjane-main-whatsHostjane__body__column__container__info__boxes__box"
														>
															<h3> {title} </h3>
															<p>{description}</p>
														</div>
													);
												}
											)}
										</div>
										<div className="hostjane-main-whatsHostjane__body__column__container__info_bottom-info">
											<h4>{bottomText}</h4>
											<a href={bottomBtnLink}>
												{bottomBtnText}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</WhatIsHostjaneWrapper>
			<div className="top-left">
				<div className="hostjane-main-whatsHostjane__bg_strip_1" />
				<div className="hostjane-main-whatsHostjane__bg_strip_3" />
			</div>
			<div className="top-right">
				<div className="hostjane-main-whatsHostjane__bg_strip_4" />
				<div className="hostjane-main-whatsHostjane__bg_strip_2" />
			</div>

			<div className="background-mask">
				<div className="background-mask__grid">
					<div className="background-mask__grid__item" />
					<div className="background-mask__grid__item" />
					<div className="background-mask__grid__item" />
					<div className="background-mask__grid__item" />
				</div>
			</div>
		</MainWrapper>
	);
};

export default WhatIsHostjane;
