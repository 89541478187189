import React from 'react';
import { ReactComponent as Bookmark } from './bookmark.svg';
import { ReactComponent as CustomerSupport } from './customer-support.svg';
import { ReactComponent as Network } from './network.svg';
import { ReactComponent as ShieldStart } from './shield-star.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Cphosting } from './cpanel.svg';
import { ReactComponent as Woo } from './woo.svg';
import { ReactComponent as AWS } from './aws.svg';
import { ReactComponent as WordPress } from './managed-wordpress.svg';


const mapModalData = {
	titles: {
		3: { title: 'Asia Pacific', identifier: 'asia' },
		6: { title: 'Americas', identifier: 'us' },
		5: { title: 'EMEA', identifier: 'europe' },
		4: { title: 'Australia', identifier: 'oceania' },
	},
	description: `HostJane global network has 14 AWS datacenters for VPS, cloud compute, dedicated servers in prime locations.`,
	closeIcon: <Close />,
	parentServices: [
		{ icon: <AWS />, title: 'Professional Installs' },
		{ icon: <Cphosting />, title: 'High-Performance' },
		{ icon: <WordPress />, title: 'Monitoring and Alerts' },
		{ icon: <Woo />, title: 'Patched & Updated' },
	],
	boxes: [
		{
			title: 'Scalable',
			icon: <Network />,
			listItems: [
				'Resizable computes',
				'SSD Block Storage',
			],
		},
		{
			title: 'Trusted Software',
			icon: <CustomerSupport />,
			listItems: [
				'Packaged by Bitnami',
				'Library of Installers',
			],
		},
		{
			title: 'Support',
			icon: <Bookmark />,
			listItems: [
				'10 minute responses',
				'99.9% SLA',
			],
		},
		{
			title: 'Security',
			icon: <ShieldStart />,
			listItems: [
				'Server monitoring',
				'Data protection',
			],
		},
	],
};

export default mapModalData;
